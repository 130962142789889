import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  GetCourseByMentorId,
  AddMentorCourse,
  EditCourseListing,
  DeleteCourseListing,
} from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";

import { v4 as uuid } from "uuid";
import Accordion from "react-bootstrap/Accordion";
import {
  addWhiteLabelConfig,
  GetAllWhiteLabelListing,
  UpdateWhiteLabelConfig,
} from "../../services/whitelabel-config";

const PricingData = [
  {
    PricingTitle: "Career counselling",
    PricingSubTitle: "Learn about yourself to choose your right career",
    PrincingPrice: "1999",
    PricingActivatePackage: "CAREERCOUNSELLING",
    PricingFeatures: [
      {
        value: "Personality assessment",
      },
      {
        value: "Soft skills assessment",
      },
      {
        value: "Aptitude assessment",
      },
      {
        value: "AI cognitive analysis",
      },
    ],
  },
  {
    PricingTitle: "Communicate for impact",
    PricingSubTitle:
      "Learn techniques to articulate with confidence in one to one and group settings",
    PrincingPrice: "4499",
    PricingActivatePackage: "COMMUNICATION",
    PricingFeatures: [
      {
        value: "Soft skills assessment",
      },
      {
        value: "AI analysis and report",
      },
      {
        value: "Verbal communication",
      },
      {
        value: "Non-verbal communication",
      },
      {
        value: "Art of presentation",
      },
      {
        value: "Speaking in a group",
      },
    ],
  },
  {
    PricingTitle: "Crack your dream interview",
    PricingSubTitle:
      "Learn everything about cracking your next big career interview",
    PrincingPrice: "4999",
    PricingActivatePackage: "INTERVIEWPREP",
    PricingFeatures: [
      {
        value: "Communication and confidence",
      },
      {
        value: "Interview fundamentals",
      },
      {
        value: "ATS resume",
      },
      {
        value: "Elevator pitch",
      },
      {
        value: "LinkedIn",
      },
      {
        value: "Frequently asked questions",
      },
      {
        value: "AI interview",
      },
    ],
  },
  {
    PricingTitle: "Build your personal brand",
    PricingSubTitle:
      "Groom yourself to create the right impression with your stakeholders",
    PrincingPrice: "5999",
    PricingActivatePackage: "PERSONALBRANDING",
    PricingFeatures: [
      {
        value: "Personality assessment",
      },
      {
        value: "Soft skills assessment",
      },
      {
        value: "SWOT analysis",
      },
      {
        value: "AI cognitive analysis",
      },
      {
        value: "Goal setting",
      },
      {
        value: "Interpersonal skills",
      },
      {
        value: "Profile building",
      },
      {
        value: "Professional networking",
      },
      {
        value: "Resume builder",
      },
    ],
  },
];

const AdminWhiteLabelConfigureSetup = () => {
  const editor = useRef(null);

  let switchtoaddmode = () => {
    setEditMode(false);
  };

  const [whiteLabeledConfig, setwhiteLabeledConfig] = useState([]);

  const [editMode, setEditMode] = useState(false);

  const GetAllWhiteLabelListingFunc = async () => {
    const SubmitResponse = await GetAllWhiteLabelListing()
      .then((resp) => {
        console.log(resp);
        const respreverse = resp.reverse();
        setwhiteLabeledConfig(respreverse);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetAllWhiteLabelListingFunc();
  }, []);

  let EditWhiteLableHandler = (item) => {
    console.log(item);
    console.log(item.course_code);
    setEditMode(true);

    //declare Here
    const reswhitelabelMasterConfig = item.WhitelableSubdomain;
    console.log("reswhitelabelMasterConfig", reswhitelabelMasterConfig);
    const subdomain = reswhitelabelMasterConfig.WhitelableSubdomain;

    //Set Here
    setwhitelabelMasterConfig({
      WhitelableSubdomain: item.WhitelableSubdomain,
      OurServiceMainTitle: item.OurServiceMainTitle,
      OurServiceSubTitle: item.OurServiceSubTitle,
      PricingMainTitle: item.PricingMainTitle,
      IsWhiteLabledDomainBlocked: item.IsWhiteLabledDomainBlocked,
      DynamicPricingHeight: item.DynamicPricingHeight,
      DynamicImageWeightHeight: item.DynamicImageWeightHeight,
      DynamicImageWeightHeight: item.DynamicImageWeightHeight,
      DynamicImageWeightHeight1: item.DynamicImageWeightHeight1,
      DynamicWH1: item.DynamicWH1,
      DynamicWH2: item.DynamicWH2,
      DynamicWH3: item.DynamicWH3,
      DynamicWH4: item.DynamicWH4,
      DynamicWH5: item.DynamicWH5,
      DynamicWH6: item.DynamicWH6,
      DynamicWH7: item.DynamicWH7,
    });
    setWhitehero(item.Hero);
    setWhiteaboutus(item.About);
    setWhitewhyus(item.Why);
    setServicesGrid(item.OurServicesGrid);
    setWhitepricing(item.Pricing);
  };

  let DeleteWhiteLabelMasterHandler = (item) => {
    console.log(item);
  };

  let SubmitAdminWhiteLabledConfig = async () => {
    const payload = {
      WhitelableSubdomain: whitelabelMasterConfig.WhitelableSubdomain,
      GuestMenuData: [
        {
          MenuTitle: "Home",
          MenuLink: "/",
        },
      ],
      StudentMenuData: [
        {
          MenuTitle: "Home",
          MenuLink: "/",
        },
        {
          MenuTitle: "Dashboard",
          MenuLink: "/student-dashboard",
        },
      ],
      logoURL: whitelabelMasterConfig.logoURL,
      WhiteLabelCompanyDetails: {
        company_name: "",
        company_owner_name: "",
        owner_emailId: "",
        company_address: "",
        company_contact_no: "",
      },
      Hero: whitehero,
      About: whiteaboutus,
      Why: whitewhyus,
      OurServiceMainTitle: whitelabelMasterConfig.OurServiceMainTitle,
      OurServiceSubTitle: whitelabelMasterConfig.OurServiceSubTitle,
      OurServicesGrid: ServicesGrid,
      PricingMainTitle: whitelabelMasterConfig.PricingMainTitle,
      Pricing: whitepricing,
      DynamicPricingHeight: whitelabelMasterConfig.DynamicPricingHeight,
      DynamicImageWeightHeight: whitelabelMasterConfig.DynamicImageWeightHeight,
      DynamicImageWeightHeight: whitelabelMasterConfig.DynamicImageWeightHeight,
      DynamicImageWeightHeight1:
        whitelabelMasterConfig.DynamicImageWeightHeight1,
      DynamicWH1: whitelabelMasterConfig.DynamicWH1,
      DynamicWH2: whitelabelMasterConfig.DynamicWH2,
      DynamicWH3: whitelabelMasterConfig.DynamicWH3,
      DynamicWH4: whitelabelMasterConfig.DynamicWH4,
      DynamicWH5: whitelabelMasterConfig.DynamicWH5,
      DynamicWH6: whitelabelMasterConfig.DynamicWH6,
      DynamicWH7: whitelabelMasterConfig.DynamicWH7,
    };

    console.log("payload", payload);
    const SubmitResponse = await addWhiteLabelConfig(payload)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  let SubmitUpdateAdminWhiteLabledConfig = async () => {
    const payload = {
      WhitelableSubdomain: whitelabelMasterConfig.WhitelableSubdomain,
      GuestMenuData: [
        {
          MenuTitle: "Home",
          MenuLink: "/",
        },
      ],
      StudentMenuData: [
        {
          MenuTitle: "Home",
          MenuLink: "/",
        },
        {
          MenuTitle: "Dashboard",
          MenuLink: "/student-dashboard",
        },
      ],
      logoURL: whitelabelMasterConfig.logoURL,
      WhiteLabelCompanyDetails: {
        company_name: "",
        company_owner_name: "",
        owner_emailId: "",
        company_address: "",
        company_contact_no: "",
      },
      Hero: whitehero,
      About: whiteaboutus,
      Why: whitewhyus,
      OurServiceMainTitle: whitelabelMasterConfig.OurServiceMainTitle,
      OurServiceSubTitle: whitelabelMasterConfig.OurServiceSubTitle,
      OurServicesGrid: ServicesGrid,
      PricingMainTitle: whitelabelMasterConfig.PricingMainTitle,
      Pricing: whitepricing,
      DynamicPricingHeight: whitelabelMasterConfig.DynamicPricingHeight,
      DynamicImageWeightHeight: whitelabelMasterConfig.DynamicImageWeightHeight,
      DynamicImageWeightHeight: whitelabelMasterConfig.DynamicImageWeightHeight,
      DynamicImageWeightHeight1:
        whitelabelMasterConfig.DynamicImageWeightHeight1,
      DynamicWH1: whitelabelMasterConfig.DynamicWH1,
      DynamicWH2: whitelabelMasterConfig.DynamicWH2,
      DynamicWH3: whitelabelMasterConfig.DynamicWH3,
      DynamicWH4: whitelabelMasterConfig.DynamicWH4,
      DynamicWH5: whitelabelMasterConfig.DynamicWH5,
      DynamicWH6: whitelabelMasterConfig.DynamicWH6,
      DynamicWH7: whitelabelMasterConfig.DynamicWH7,
    };
    const SubmitResponse = await UpdateWhiteLabelConfig(payload)
      .then((resp) => {
        console.log("resp", resp);
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const [whitelabelMasterConfig, setwhitelabelMasterConfig] = useState({
    WhitelableSubdomain: "",
    logoURL: "",
    OurServiceMainTitle: "",
    OurServiceSubTitle: "",
    PricingMainTitle: "",
    IsWhiteLabledDomainBlocked: "",
    DynamicPricingHeight: "",
    DynamicImageWeightHeight: "",
    DynamicImageWeightHeight1: "",
    DynamicWH1: "",
    DynamicWH2: "",
    DynamicWH3: "",
    DynamicWH4: "",
    DynamicWH5: "",
    DynamicWH6: "",
    DynamicWH7: "",
    DynamicWH8: "",
  });

  let whitelabelMasterConfigonChange = (evt) => {
    const value = evt.target.value;
    setwhitelabelMasterConfig({
      ...whitelabelMasterConfig,
      [evt.target.name]: value,
    });
    console.log(whitelabelMasterConfig);
  };

  const [whitehero, setWhitehero] = useState({
    HighLightedTitleBefore: "",
    HighLightedTitle: "",
    HighLightedTitleAfter: "",
    HeroSubTitle: "",
    HeroBanner: "",
  });

  let WhiteHeroonChange = (evt) => {
    const value = evt.target.value;
    setWhitehero({
      ...whitehero,
      [evt.target.name]: value,
    });
    console.log(whitehero);
  };

  const [whiteaboutus, setWhiteaboutus] = useState({
    AboutMainTitle: "",
    AboutDescription: "",
    AboutImage: "",
  });

  let richtext = (e) => {
    let clean = DOMPurify.sanitize(e);
    setWhiteaboutus({ ...whiteaboutus, AboutDescription: clean });
    console.log("AboutDescription", whiteaboutus.AboutDescription);
  };

  let WhiteaboutusonChange = (evt) => {
    const value = evt.target.value;
    setWhiteaboutus({
      ...whiteaboutus,
      [evt.target.name]: value,
    });
    console.log(whiteaboutus);
  };

  const [whitewhyus, setWhitewhyus] = useState({
    WhyMainTitle: "",
    WhyDescription: "",
    WhyImage: "",
  });
  let Whyrichtext = (e) => {
    let clean = DOMPurify.sanitize(e);
    setWhitewhyus({ ...whitewhyus, WhyDescription: clean });
    console.log("WhyDescription", whitewhyus.WhyDescription);
  };
  let WhitewhyusnChange = (evt) => {
    const value = evt.target.value;
    setWhitewhyus({
      ...whitewhyus,
      [evt.target.name]: value,
    });
    console.log(whitewhyus);
  };

  const [ServicesGrid, setServicesGrid] = useState([
    {
      GridTitle: "",
      GridDescription: "",
    },
  ]);
  let ServicesGridhandleChange = (i, e) => {
    let item = [...ServicesGrid];
    item[i][e.target.name] = e.target.value;
    ServicesGrid[i][e.target.name] = item[i][e.target.name];
    setServicesGrid(ServicesGrid);
    setServicesGrid([...ServicesGrid]);
    console.log(ServicesGrid);
  };

  let addServicesGridFields = () => {
    setServicesGrid([
      ...ServicesGrid,
      {
        GridTitle: "",
        GridDescription: "",
      },
    ]);
  };

  let removeServicesGridFields = (i) => {
    let item = [...ServicesGrid];
    item.splice(i, 1);
    setServicesGrid(item);
  };

  const [whitepricing, setWhitepricing] = useState([
    {
      PricingTitle: "",
      PricingSubTitle: "",
      PrincingPrice: "",
      PricingActivatePackage: "",
      PricingFeatures: [],
    },
  ]);

  const WhitePricinghandleInputChange = (index, event) => {
    const values = [...whitepricing];
    values[index][event.target.name] = event.target.value;
    setWhitepricing(values);
  };

  const WhitePricinghandleSubInputChange = (parentIndex, childIndex, event) => {
    const values = [...whitepricing];
    values[parentIndex].PricingFeatures[childIndex].value = event.target.value;
    setWhitepricing(values);
  };

  //Not Being Used
  // const WorkExpaddInputField = (index) => {
  //   const values = [...WorkExprience];
  //   values[index].description.push({ value: "" });
  //   setWorkExprience(values);
  // };

  const WhitePricingaddSubInputField = (parentIndex) => {
    const values = [...whitepricing];
    values[parentIndex].PricingFeatures.push({ value: "" });
    setWhitepricing(values);
  };
  const WhitePricingremoveInputField = (index) => {
    const values = [...whitepricing];
    values.splice(index, 1);
    setWhitepricing(values);
  };

  const WhitePricingremoveSubInputField = (parentIndex, childIndex) => {
    const values = [...whitepricing];
    values[parentIndex].PricingFeatures.splice(childIndex, 1);
    setWhitepricing(values);
  };

  const addMyGreenHornDefaultPricing = () => {
    setWhitepricing(PricingData);
  };

  const ClearPricing = () => {
    setWhitepricing([]);
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Manage Course"} />

      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col className="divscroll">
            <Card className="MainCard">
              <div className="section__title-wrapper mb-45">
                <h2 className="section__title">
                  All{" "}
                  <span className="yellow-bg">
                    White Label{" "}
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                  </span>
                  Listing
                </h2>
              </div>
              {whiteLabeledConfig.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <p className="jobtitle">
                          <b className="boldtitle">
                            White Label Title: &nbsp;&nbsp;
                            {item.WhitelableSubdomain}
                          </b>
                        </p>
                      </Row>

                      <Row>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => EditWhiteLableHandler(item)}
                          >
                            Edit
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => DeleteWhiteLabelMasterHandler(item)}
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="MainCard">
              {editMode == false ? (
                <>
                  <Col>
                    <div className="section__title-wrapper mb-45">
                      <h2 className="section__title">
                        <span className="yellow-bg">
                          Add{" "}
                          <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                        </span>
                        White Label
                      </h2>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Container>
                    <Row>
                      <Col sm={8}>
                        <div className="section__title-wrapper mb-45">
                          <h2 className="section__title">
                            Edit{" "}
                            <span className="yellow-bg">
                              White Label{" "}
                              <img
                                src="/assets/img/shape/yellow-bg-2.png"
                                alt=""
                              />
                            </span>
                            Listing
                          </h2>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <button className="e-btn" onClick={switchtoaddmode}>
                          Switch To Add Mode
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}

              <Container>
                <div className="MobTextStyle">
                  <div className="WHITELABELBASICDETAILS">
                    {editMode == false ? (
                      <>
                        {" "}
                        <input
                          placeholder="White lable Sub domain Name"
                          className="form-control marginTop5"
                          type="text"
                          name="WhitelableSubdomain"
                          value={whitelabelMasterConfig.WhitelableSubdomain}
                          onChange={whitelabelMasterConfigonChange}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <input
                          placeholder="White lable Sub domain Name"
                          className="form-control marginTop5"
                          type="text"
                          name="WhitelableSubdomain"
                          value={whitelabelMasterConfig.WhitelableSubdomain}
                          onChange={whitelabelMasterConfigonChange}
                          disabled
                        />
                      </>
                    )}

                    <input
                      placeholder="Our Package Main Title"
                      className="form-control marginTop5"
                      type="text"
                      name="PricingMainTitle"
                      value={whitelabelMasterConfig.PricingMainTitle}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="Pricing column height"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicPricingHeight"
                      value={whitelabelMasterConfig.DynamicPricingHeight}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="DynamicImageWeightHeight"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicImageWeightHeight"
                      value={whitelabelMasterConfig.DynamicImageWeightHeight}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="Is This Sub Domain Blocked"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicImageWeightHeight1"
                      value={whitelabelMasterConfig.DynamicImageWeightHeight1}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="Is This Sub Domain Blocked"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicWH1"
                      value={whitelabelMasterConfig.DynamicWH1}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="DynamicWH 2"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicWH2"
                      value={whitelabelMasterConfig.DynamicWH2}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="DynamicWH 3"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicWH3"
                      value={whitelabelMasterConfig.DynamicWH3}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="DynamicWH 4"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicWH4"
                      value={whitelabelMasterConfig.DynamicWH4}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="DynamicWH 5"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicWH5"
                      value={whitelabelMasterConfig.DynamicWH5}
                      onChange={whitelabelMasterConfigonChange}
                    />
                    <input
                      placeholder="DynamicWH 6"
                      className="form-control marginTop5"
                      type="text"
                      name="DynamicWH6"
                      value={whitelabelMasterConfig.DynamicWH6}
                      onChange={whitelabelMasterConfigonChange}
                    />
                  </div>
                  <div className="HERO">
                    <h2 className="pt-10 pb-20">Hero Slider Fields</h2>
                    <input
                      placeholder="HighLighted Title Before"
                      className="form-control marginTop5"
                      type="text"
                      name="HighLightedTitleBefore"
                      value={whitehero.HighLightedTitleBefore}
                      onChange={WhiteHeroonChange}
                    />
                    <input
                      placeholder="HighLighted Title"
                      className="form-control marginTop5"
                      type="text"
                      name="HighLightedTitle"
                      value={whitehero.HighLightedTitle}
                      onChange={WhiteHeroonChange}
                    />
                    <input
                      placeholder="HighLighted Title After"
                      className="form-control marginTop5"
                      type="text"
                      name="HighLightedTitleAfter"
                      value={whitehero.HighLightedTitleAfter}
                      onChange={WhiteHeroonChange}
                    />
                    <input
                      placeholder="Hero SubTitle"
                      className="form-control marginTop5"
                      type="text"
                      name="HeroSubTitle"
                      value={whitehero.HeroSubTitle}
                      onChange={WhiteHeroonChange}
                    />
                  </div>
                  <div className="ABOUTUS">
                    <h2 className="pt-10 pb-20">About Us Fields</h2>
                    <input
                      placeholder="About Main Title"
                      className="form-control marginTop5"
                      type="text"
                      name="AboutMainTitle"
                      value={whiteaboutus.AboutMainTitle}
                      onChange={WhiteaboutusonChange}
                    />

                    <JoditEditor
                      ref={editor}
                      value={whiteaboutus.AboutDescription}
                      config={config}
                      tabIndex={5} // tabIndex of textarea
                      onBlur={(newContent) => richtext(newContent)} // preferred to use only this option to update the content for performance reasons
                      //onChange={(newContent) => richtext(newContent)}
                    />
                  </div>
                  <div className="WHYUS">
                    <h2 className="pt-10 pb-20">Why Us Fields</h2>
                    <input
                      placeholder="Why Main Title"
                      className="form-control marginTop5"
                      type="text"
                      name="WhyMainTitle"
                      value={whitewhyus.WhyMainTitle}
                      onChange={WhitewhyusnChange}
                    />
                    <JoditEditor
                      ref={editor}
                      value={whitewhyus.WhyDescription}
                      config={config}
                      tabIndex={5} // tabIndex of textarea
                      onBlur={(newContent) => Whyrichtext(newContent)} // preferred to use only this option to update the content for performance reasons
                      //onChange={(newContent) => Whyrichtext(newContent)}
                    />
                  </div>
                </div>
                <Card className="carddesign marginTop5">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Our Services Area</Accordion.Header>
                      <Accordion.Body>
                        <Card.Body>
                          <Card.Text>
                            <input
                              placeholder="Our Services Main Title"
                              className="form-control marginTop5"
                              type="text"
                              name="OurServiceMainTitle"
                              value={whitelabelMasterConfig.OurServiceMainTitle}
                              onChange={whitelabelMasterConfigonChange}
                            />
                            <input
                              placeholder="Our Services Sub Title"
                              className="form-control marginTop5"
                              type="text"
                              name="OurServiceSubTitle"
                              value={whitelabelMasterConfig.OurServiceSubTitle}
                              onChange={whitelabelMasterConfigonChange}
                            />
                            <Card.Title className="pt-10 pb-10">
                              Add Services Grid Item
                            </Card.Title>
                            {ServicesGrid.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <input
                                  placeholder="Grid Title"
                                  className="form-control"
                                  type="text"
                                  name="GridTitle"
                                  value={element.GridTitle}
                                  onChange={(e) =>
                                    ServicesGridhandleChange(index, e)
                                  }
                                />
                                <input
                                  placeholder="Grid Description"
                                  className="form-control"
                                  type="text"
                                  name="GridDescription"
                                  value={element.GridDescription}
                                  onChange={(e) =>
                                    ServicesGridhandleChange(index, e)
                                  }
                                />

                                {index ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary button remove"
                                    onClick={() =>
                                      removeServicesGridFields(index)
                                    }
                                  >
                                    Remove
                                  </button>
                                ) : null}
                                <hr />
                              </div>
                            ))}
                            <div className="button-section">
                              <button
                                className="e-btn"
                                type="button"
                                onClick={() => addServicesGridFields()}
                              >
                                Add
                              </button>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card>

                <Card className="carddesign marginTop5">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Our Pricing Area</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-md-3">
                            {" "}
                            <button
                              className="e-btn"
                              type="button"
                              onClick={() => addMyGreenHornDefaultPricing()}
                            >
                              set MyGreenHorn Pricing
                            </button>
                          </div>
                          <div className="col-md-3">
                            {" "}
                            <button
                              className="e-btn"
                              type="button"
                              onClick={() => ClearPricing()}
                            >
                              Clear Pricing
                            </button>
                          </div>
                        </div>

                        <Card.Body>
                          <Card.Text>
                            {whitepricing.map((field, index) => (
                              <div key={index}>
                                <input
                                  placeholder="Pricing Title"
                                  className="form-control"
                                  type="text"
                                  name="PricingTitle"
                                  value={field.PricingTitle}
                                  onChange={(event) =>
                                    WhitePricinghandleInputChange(index, event)
                                  }
                                />
                                <input
                                  placeholder="Pricing Sub Title"
                                  className="form-control"
                                  type="text"
                                  name="PricingSubTitle"
                                  value={field.PricingSubTitle}
                                  onChange={(event) =>
                                    WhitePricinghandleInputChange(index, event)
                                  }
                                />
                                <input
                                  placeholder="Princing Price"
                                  className="form-control"
                                  type="text"
                                  name="PrincingPrice"
                                  value={field.PrincingPrice}
                                  onChange={(event) =>
                                    WhitePricinghandleInputChange(index, event)
                                  }
                                />
                                <input
                                  placeholder="Pricing Activate Package"
                                  className="form-control"
                                  type="text"
                                  name="PricingActivatePackage"
                                  value={field.PricingActivatePackage}
                                  onChange={(event) =>
                                    WhitePricinghandleInputChange(index, event)
                                  }
                                />
                                {field.PricingFeatures.map(
                                  (subField, subIndex) => (
                                    <div key={subIndex}>
                                      <div className="row">
                                        <div
                                          style={{ padding: 2 }}
                                          className="col-md-10"
                                        >
                                          {" "}
                                          <textarea
                                            placeholder="Add Package Feature"
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            rows="10"
                                            cols="35"
                                            value={subField.value}
                                            onChange={(event) =>
                                              WhitePricinghandleSubInputChange(
                                                index,
                                                subIndex,
                                                event
                                              )
                                            }
                                          ></textarea>
                                        </div>
                                        <div
                                          style={{ padding: 3, marginTop: 5 }}
                                          className="col-md-2"
                                        >
                                          <button
                                            type="button"
                                            className="e-btn"
                                            style={{
                                              color: "white",
                                              background: "red",
                                            }}
                                            onClick={() =>
                                              WhitePricingremoveSubInputField(
                                                index,
                                                subIndex
                                              )
                                            }
                                          >
                                            X
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                                <div className="row">
                                  <div className="col-md-6">
                                    <button
                                      className="e-btn"
                                      type="button"
                                      style={{
                                        height: 75,
                                        lineHeight: 1,
                                        color: "white",
                                        padding: 5,
                                      }}
                                      onClick={() =>
                                        WhitePricingaddSubInputField(index)
                                      }
                                    >
                                      Add feature bullets
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      className="e-btn"
                                      style={{
                                        color: "white",
                                        background: "red",
                                        height: 75,
                                        lineHeight: 1,
                                        padding: 5,
                                      }}
                                      type="button"
                                      onClick={() =>
                                        WhitePricingremoveInputField(index)
                                      }
                                    >
                                      Delete Pricing
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="e-btn"
                              style={{ marginTop: 5 }}
                              onClick={() =>
                                setWhitepricing([
                                  ...whitepricing,
                                  {
                                    PricingTitle: "",
                                    PricingSubTitle: "",
                                    PrincingPrice: "",
                                    PricingActivatePackage: "",
                                    PricingFeatures: [],
                                  },
                                ])
                              }
                            >
                              Add next Pricing Column
                            </button>
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card>
              </Container>
              {editMode == false ? (
                <>
                  <button
                    className="e-btn"
                    onClick={SubmitAdminWhiteLabledConfig}
                  >
                    Add Submit
                  </button>
                </>
              ) : (
                <button
                  className="e-btn marginTop5"
                  onClick={SubmitUpdateAdminWhiteLabledConfig}
                >
                  Edit Submit
                </button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default AdminWhiteLabelConfigureSetup;
